<template>
<div class="news_photo dirr">
    <div class="news_con d-none d-sm-flex">
        <div class="most" v-if="mostNews_skeleton">
            <div class="title" style="padding:0 20px 20px 20px;">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <p class="tl">{{ $t("most_highlight_pic_news") }}</p>
            </div>
            <div class="items">
                <v-layout row>
                <v-flex sm12 xs12 md8 lg8 style="padding:0 10px;">
                    <v-skeleton-loader  width="800" min-width="800" type="image, image, image"></v-skeleton-loader>
                </v-flex>
                <v-flex sm12 xs12 md4 lg4 style="padding:0 10px;">
                    <v-skeleton-loader  width="425" min-width="425" height="180" type="image"></v-skeleton-loader><br>
                    <v-skeleton-loader  width="425" min-width="425" height="180" type="image"></v-skeleton-loader><br>
                    <v-skeleton-loader  width="425" min-width="425" height="180" type="image"></v-skeleton-loader>
                </v-flex>
                </v-layout>
            </div>
        </div>
        <div class="most" v-else>
            <div class="title" style="padding:0 20px 20px 20px;">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <p class="tl">{{ $t("Picture_Reports") }}</p>
            </div>
            <div class="items">
                <v-layout row>
                <v-flex sm7 xs12 md8 lg8 style="padding:0 10px;">
                    <router-link :to="'/new/'+mostNews[0].id+'?lang='+$i18n.locale" class="item Fitem">
                        <div class="img">
                            <v-img height="100%" :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+mostNews[0].image"
                                :src="'https://alkafeel.net/news/mcroped96/600/'+mostNews[0].image" style="border-radius: 4px;">
                            </v-img>
                            <p class="tl ta">{{mostNews[0].title}}</p>
                        </div>
                    </router-link>
                </v-flex>
                <v-flex sm5 xs12 md4 lg4 style="padding:0 10px;">
                    <router-link v-for="i in mostNews.slice(1)" :key="i" :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item Oitem">
                        <div class="img">
                            <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                :src="'https://alkafeel.net/news/mcroped96/625/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                            </v-img>
                            <p class="tl ta">{{i.title}}</p>
                        </div>
                    </router-link>
                </v-flex>
                </v-layout>
            </div>
        </div>
        <div class="reports">
            <div class="items">
                <v-layout row>
                <v-flex sm6 xs12 md6 lg6 v-for="i in picNews" :key="i" style="padding:0 10px;">
                    <router-link :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item">
                        <div class="img">
                            <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                :src="'https://alkafeel.net/news/mcroped96/625/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                            </v-img>
                            <p class="tl ta">{{i.title}}</p>
                        </div>
                    </router-link>
                </v-flex>
                </v-layout>
            </div>
            <v-btn v-if="getMorenews" width="150" height="40" class="ma-2 tl" style="align-self:center !important;" :loading="loading" color="rgba(177, 189, 82, 1)" @click="grtpicNews()">
                <p class="getMorenews alistSub tl">{{ $t('view_more') }}</p>
            </v-btn>
        </div>
    </div>
     <v-container>
        <div class="mobile_con d-flex d-sm-none">
        <div class="most">
            <div class="title" style="padding:0 20px 20px 20px;">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <p class="tl">{{ $t("Picture_Reports") }}</p>
            </div>
            <router-link v-for="i in mostNews" :key="i" :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item Fitem">
                <div class="img">
                    <v-img height="100%" :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                        :src="'https://alkafeel.net/news/mcroped96/800/'+i.image" style="border-radius: 4px;">
                    </v-img>
                    <p class="tl ta">{{i.title}}</p>
                </div>
            </router-link>

            <!-- <router-link v-for="i in mostNews.slice(1)" :key="i" :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item Oitem">
                <div class="img">
                    <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                        :src="'https://alkafeel.net/news/mcroped96/625/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                    </v-img>
                </div>
                <p class="tl ta">{{i.title}}</p>
            </router-link> -->

        </div>
        <div class="reports">
            <div class="items">
                <router-link v-for="i in picNews" :key="i" :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item">
                    <div class="img" style="    height: 370px !important;">
                        <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                            :src="'https://alkafeel.net/news/mcroped96/625/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                        </v-img>
                        <p class="tl ta">{{i.title}}</p>
                    </div>
                </router-link>
            </div>
            <v-btn v-if="getMorenews" width="150" height="40" class="ma-2 tl" style="align-self:center !important;" :loading="loading" color="rgba(177, 189, 82, 1)" @click="grtpicNews()">
                <p class="alistSub tl" style="    font-size: 18px !important">{{ $t('view_more') }}</p>
            </v-btn>
        </div>
    </div>


   <news-search></news-search>


 </v-container>
</div>
</template>

<script>
import searchVue from './search.vue';
export default {
    data() {
        return {
            mostNews : [{"id":"","title":"","text":"","pic":""}],
            picNews : [],
            mostNews_skeleton : true,
            NewsPage : 1,
            getMorenews : true,
            loader: null,
            loading: false,
            offsetTop: 0,
            axios_load: false
        }
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 2000)
            this.loader = null
        },
        offsetTop (val) {
            this.callbackFunc()
        }
    },
    components: {
        'news-search': searchVue
    },
    directives: {},
    computed: {},
    mounted() {
        document.title =this.$t("Picture_Reports"); 
    }, 
    created () {
    //    this.grtmostNews ();
        this.grtpicNews();
        window.addEventListener('scroll', this.onScroll);
    },
    methods:{
        onScroll (e) {
            setTimeout(() => {
                if (!this.axios_load) {this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;}
            }, 700);
        },
        isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        callbackFunc() {
            let el = document.querySelector('.getMorenews');
            if (this.isElementInViewport(el)) {this.grtpicNews();}
        },
        grtmostNews () {
            this.$axios.get("articles/Getphotonews/4?page=1", {
            })
            .then(res => {
                // this.NewsPage ++;
                this.mostNews = res.data.data;
                this.grtpicNews();
                setTimeout(() => this.mostNews_skeleton = false, 500);
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        grtpicNews () {
            this.axios_load = true;
            this.loader = 'loading';
            this.$axios.get("articles/Getphotonews/9?page=" + this.NewsPage, {
            })
            .then(res => {
                this.axios_load = false;
                this.mostNews_skeleton = false
                this.NewsPage ++;
                if (this.NewsPage === 2) {
                    this.mostNews = res.data.data.slice(0,4);
                    this.picNews = res.data.data.slice(4);
                } else {
                    this.picNews = this.picNews.concat(res.data.data);
                }
                if (res.data.data.length < 9) {this.getMorenews = false;}
                //console.log(res.data.data);
            })
            .catch(() => {
            });
        },
    },
    filters: {
        subStrArtTitle: function (string) {if (string != null) {return string.substring(0, 24) + '...';}},
        subStrlastNewsitle: function (string) {if (string != null) {return string.substring(0, 130) + '...';}},
    },
}
</script>