<template>
    <div>
        <v-overlay :class="searchDesc ? '' : 'search_overlay_result'" class="search_overlay messiri_fonted" :value="searchOverlay" color="#f3f3f3" opacity="0.9" >

            <div class="d-flex">
                <span @click="searchClose" style="content: url('/assets/img/close.svg'); width:25px; margin: 0 8px; vertical-align: bottom; cursor:pointer"></span>
                <span class="footer_desc">{{$t('close')}}</span>
            </div>

            <v-img src="/assets/img/footer_logo2.svg" height="65" class="mx-auto" width="319"></v-img>

            <div class="footer_desc mt-3 text-center"> {{$t('alkafeel_title')}}  - {{ $t('alkafeel_desc') }}</div>

            <v-layout class="messiri_fonted" row mt-6 mb-6 align-center style="text-align:center">
                <div class="overlay_title">
                    <span style="content: url('/assets/img/footer_title_ico.svg'); vertical-align: bottom;"></span>
                    {{$t('search')}}
                </div>
            </v-layout>

            <v-layout row>
                <v-text-field v-model="searchTerm" @change="searchChange" @keyup.enter="search" solo flat light color="success" :label="$t('action_search')"  hide-details="auto" outlined :rules="SearchRules" >
                <v-btn color="#14A79D" slot="append" @click="search">
                    <!-- <v-icon color="#FFF">mdi-magnify</v-icon> -->
                    <div class="news_serv" style="padding-top:3px"><svg viewBox="0 0 26 25"><use xlink:href="/assets/img/services/search.svg#view"></use></svg></div>
                </v-btn>
                </v-text-field>
            </v-layout>

            <v-layout row light>
                <div v-if="no_results" style="color: #c00">{{ $t('msg_no_result') }}</div>
            </v-layout>



            <template v-if="searchDesc">
                    <v-layout row dark mt-10 mb-3 class="overlay_sub_title hidden-sm-and-down">
                        <span style="content: url('/assets/img/footer_title_ico.svg');" class="overlay_sub_ico"></span>
                        <span style="margin:4px 5px">{{$t('search_tags')}}</span>
                    </v-layout>
                    <v-layout row dark class="hidden-sm-and-down">
                        <template v-for="tag in tg">
                            <router-link @click="searchOverlay != searchOverlay" :to="'/newscat/'+tag.req+'?lang='+$i18n.locale" :key="tag" style="text-decoration: none; padding: 10px">
                                <span style="color: #14A79D"> # </span>
                                <span style="color: #5B5B5B; opacity:.7; font-weight: bold">{{tag.title}}</span>
                            </router-link>
                        </template>
                    </v-layout>
            </template>

            <template v-else>
                <template v-if="searchLoading">
                    <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="image"></v-skeleton-loader>
                    <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="image"></v-skeleton-loader>
                    <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="image"></v-skeleton-loader>
                    <v-skeleton-loader style="margin: 20px;" v-bind="attrs" type="image"></v-skeleton-loader>
                </template>
                <template v-else>
                    <template v-for="(result, i) in searchResults">
                        <template v-if="i == 'news'">
                            <template v-if="result[0]">

                                <v-layout row class="search_res mx-auto dirr" :key="result">
                                            
                                    <template v-for="(sec, k) in result">
                                        <v-flex sm3 v-if="k != 'more'" style="color:#000; padding:25px" :key="sec">
                                            <template>
                                                <v-card :to="sec.url" flat light>
                                                    <v-img aspect-ratio="1" max-height="185" :src="sec.image" class="sw_item"></v-img>
                                                    <v-card-subtitle v-if="sec.title != ''" class="search_title" :title="sec.title">{{sec.title.substring(0,70)+".."}}</v-card-subtitle>
                                                </v-card>
                                            </template>
                                        </v-flex>
                                    </template>
                                            
                                </v-layout>

                            </template>
                            
                        </template>

                        <template v-else-if="i == 'pages'">
                            <template v-if="result[0]">
                                <div :key="result" class="text-center">
                                    <!-- <v-pagination v-model="page" :length="6"></v-pagination> -->
                                    <div style="display: flex;justify-content: center;">
                                        <template v-for="p in result" >
                                            <template v-if="p.query !== null">
                                                <span class="paging_item" @click="search(p.query)" :key="p">{{p.page}}</span>
                                            </template>
                                            <template v-else>
                                                <span class="paging_item paging_selected" :key="p">{{p.page}}</span>
                                            </template>
                                            
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>
                </template>
            </template>
        </v-overlay>



        <div class="service_bar" :class="{hide: drawer}" :style="$vuetify.rtl ? 'left:20px;' : 'right:20px;'">

            <div class="service_ico" @click="searchOverlay = !searchOverlay">
                <div class="news_serv">
                    <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/services/search.svg#view"></use></svg>
                </div>
                <div class="service_ico_def tl">{{ $t('search') }}</div>
            </div>

            <router-link :to="'/newscat/all?lang='+$i18n.locale" class="hidden-md-and-down" style="text-decoration: none;">
                <div class="service_ico mt-2" >
                    <div class="news_serv">
                        <svg viewBox="0 0 23 25"><use xlink:href="/assets/img/services/least-news.svg#view"></use></svg>
                    </div>
                    <div class="service_ico_def tl">{{ $t('all_News') }}</div>
                </div>
            </router-link>

            <router-link :to="'/stream?lang='+$i18n.locale"  class="hidden-md-and-down" style="text-decoration: none;">
                <!-- <div class="service_ico" style="margin-top:7px" @click="targrtLink('https://alkafeel.net/live/?lang='+lang)"> -->
                <div class="service_ico" style="margin-top:7px" >
                    <div class="news_serv">
                        <svg viewBox="0 0 23 25"><use xlink:href="/assets/img/services/live.svg#view"></use></svg>
                    </div>
                    <div class="service_ico_def tl">{{ $t('index_live') }}</div> 
                </div>
            </router-link>

            <router-link :to="'/contact?lang='+$i18n.locale" class="hidden-md-and-down" style="text-decoration: none;">
                <div class="service_ico" >
                    <div class="news_serv">
                        <svg viewBox="2 0 23 25"><use xlink:href="/assets/img/services/contact.svg#view"></use></svg>
                    </div>
                    <div class="service_ico_def tl">{{ $t('index_callus') }}</div>
                </div>
            </router-link>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // search data
            drawer: false,
            searchOverlay: false,
            SearchRules: [value => !!value || this.$t("required_field"), value => (value && value.length >= 3) || this.$t("three_letters"),],
            searchTerm: null,
            searchResults: [],
            no_results: false,
            searchLoading: true,
            searchDesc: true,
            page: 1  
        }
         
    },
    watch: {
        '$route' () {
            this.searchOverlay = false;
        }
    },
    created () {
        this.$axios.get('https://alkafeel.net/main_bg/data/news_tags_main.json')
        .then(d => {
            this.tg = d.data; 
        })
    },
    methods: {
        search(page = 0) {
            var params = new URLSearchParams();
            params.append('search_term', this.searchTerm);
            params.append('lang', this.$i18n.locale);
            params.append('p', page);
            this.$axios.post('https://alkafeel.net/Core/API/news_search.php', params).then(response => {
                // console.log(response.data);
                if (response.data.status === 'OK') {
                    this.searchDesc = false
                } else {
                    this.no_results = true
                }
                this.searchLoading = false;
                this.searchResults = response.data
                // console.log(response.data)
            })
        },
        searchChange () {
            this.no_results = false;
            // this.searchDesc = true
        },
        searchClose() {
            this.searchOverlay = false;
            this.searchTerm = null;
            this.searchResults = [];
            this.no_results = false;
            this.searchDesc = true;
            
            
        }
    }
}
</script>


<style scoped>
    .related_overlay >>> .v-overlay__content,  .search_overlay >>> .v-overlay__content,  .langs_overlay >>> .v-overlay__content{
        width: 80%;
    }
    .paging_item {
        background: #1e1e1e;
        color: #fff;
        -webkit-appearance: button;
        border-radius: 4px;
        font-size: 1rem;
        height: 34px;
        margin: .3rem;
        min-width: 34px;
        padding: 5px;
        text-decoration: none;
        transition: .3s cubic-bezier(0,0,.2,1);
        width: auto;
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
        cursor: pointer;
    }
    .paging_selected {
        background-color: #2196f3 !important;
    }
    .search_overlay_result {
        position: absolute!important;
        bottom: unset!important;
        padding-bottom: 300px;
    }
    .drawer {
        position: absolute;
        top:0!important;
        right:0;
        left:0;
        width:100%!important;
        bottom: 0;
    }
    .search_more {
        padding-top:5%
    }
    .overlay_sub_title {
        font-weight: bold;
        color: #5B5B5B;
    }
    .overlay_sub_ico {
        height: 32px;
        display: ruby-text-container;
        margin: 0 5px; 
    }
    .overlay_title {
        color: #5B5B5B; 
        vertical-align: top; 
        line-height: 40px; 
        font-size:30px; 
        width:100%
    }

    .service_bar {
        position: fixed;
        top: 250px;
        background: rgba(0, 0, 0, 0);
        z-index: 2;
    }
    @media (max-width: 1264px) {
        .service_bar {
            top: 90px;
            position: absolute
        }
    }
    .service_ico {
        width: 60px;
        text-align: center;
        cursor: pointer;
        padding-bottom: 8px;
        z-index: 5;
    }
    .service_ico img {
        max-width: 50px;
    }

    .news_serv {
        fill:#000;
        width: 25px;
        height: 25px;
        margin: 0 auto 5px;
    }
    .service_ico:hover .news_serv{
        fill: #B1BD52!important;
    }

    .footer_desc {
        color: #5B5B5B;
        font-size: 14px;
    }
    .service_ico_def {
        color: #000;
        font-size: 10px;
    }
    .service_ico:hover .service_ico_def{
        color: #B1BD52 !important;
    }
    .overlay_sub_title {
        font-weight: bold;
        color: #5B5B5B;
    }
</style>